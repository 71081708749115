<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="规格模板名称" prop="label">
        <a-input v-model="form.label" placeholder="请输入" />
      </a-form-model-item>
      <a-button v-if="form.specs.length < 4" type="primary" style="width: 30%" @click="add(1)" class="margin-bottom">
        <a-icon type="plus" /> 添加规格
      </a-button>
      <div class="">
        <div class="box" v-for="(item, index) in form.specs" :key="index">
          <a-popconfirm title="确定要删除此规格吗?" ok-text="确定" cancel-text="取消" @confirm="delSpecs(index)">
            <a-icon type="close-circle" class="delSpec" />
          </a-popconfirm>
          
          <a-form-model-item layout="inline" label="规格名称" class="margin-bottom-0" style="width:44%">
            <a-input v-model="item.label" placeholder="请输入" />
          </a-form-model-item>
          <a-row>
            <a-col :span="12" v-for="(item1, index1) in item.spec_value" :key="index">
              <a-form-model-item label="规格值" class="margin-bottom-0 "
                :class="(index1 + 1) % 2 === 0 ? 'padding-left-sm' : (index1 + 1) % 2 === 1 ? 'padding-right-sm' : ''">
                <div class="flex align-center">
                  <a-input v-model="item1.label" placeholder="请输入" />
                  <a-button v-if="(index1 + 1) === item.spec_value.length" type="primary" @click="add(2, index, index1)">
                    添加
                  </a-button>
                  <a-icon v-else type="minus-circle-o" class="margin-left-xs" @click="del(2, index, index1)"></a-icon>
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import * as goods from '@/api/mall/goods'
import { list } from '@/api/mall/goodscate'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: 'CreateForm',
  props: {

  },
  components: {

  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      formTitle: '',
      form: {
        "id": 1,
        "label": "型号",
        "specs": [
          {
            "id": 94,
            "label": "颜色",
            "spec_value": [
              {
                "id": 302,
                "label": "宝蓝",
                "thumb": null
              },
              {
                "id": 303,
                "label": "卡其",
                "thumb": null
              }
            ]
          },
          {
            "id": 95,
            "label": "尺码",
            "spec_value": [
              {
                "id": 304,
                "label": "80"
              },
              {
                "id": 305,
                "label": "90"
              },
              {
                "id": 306,
                "label": "100"
              },
              {
                "id": 307,
                "label": "110"
              },
              {
                "id": 308,
                "label": "120"
              }
            ]
          }
        ]
      },
      replaceFields: {
        title: 'label',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      cateList: [],
      cateList3: [],  // 只有三层的分类
      statusOptions: [
        {
          label: '显示',
          value: 1
        },
        {
          label: '隐藏',
          value: 0
        }
      ],
      status: 1,
      // 上传图片
      open: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      rules: {
        label: [{ required: true, message: '规格名称不能为空', trigger: 'blur' }],
      },
    }
  },
  filters: {
  },
  created() {
    this.getGoodsCate()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // 获取商品分类
    getGoodsCate() {
      this.loading = false
      // 商品分类最多是三级，所以回显示时只显示第一级和第二级，把第三级的分类删除掉
      let arr = []
      let arr3 = JSON.parse(JSON.stringify(this.cateList))
      arr3.map((item) => {
        if (item.children && item.children.length) {
          item.children.map(item1 => {
            if (item1.children && item1.children.length) {
              delete item1.children
            }
          })
        }
      })
      console.log('arr3', arr3)
      this.cateList3 = arr3
      return
      list.then(res => {
        if (res.success) {
          let arr = []
          let arr3 = JSON.parse(JSON.stringify(this.cateList))
          arr3.map((item) => {
            if (item.children && item.children.length) {
              item.children.map(item1 => {
                if (item1.children && item1.children.length) {
                  delete item1.children
                }
              })
            }
          })
          this.cateList3 = arr3
        }
      }).catch(err => {
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        "label": "",
        "specs": [
          {
            "label": "",
            "spec_value": [
              {
                "label": "",
                "thumb": ""
              }
            ]
          },
        ],
      }
    },
    getCateList() { },
    /** 新增按钮操作 */
    handleAdd() {
      this.formTitle = '添加规格'
      this.reset()
      this.open = true
      // 拉平分类数据
      // this.cateList
      console.log('分类数据', this.cateList)
      this.getCateList()
      /** 
      let data = []
      const flatten = (arr) => {
        arr.map(item => {
          if (item.children && item.children.length) {
            console.log('进俩了吗')
            let obj = JSON.parse(JSON.stringify(item))
            delete obj.children
            data.push(obj)
            flatten(item.children)
          } else {
            data.push(item)
          }
        })
        return data
      }
      let newFlatData = flatten(this.cateList)
      console.log('拉平后的分类数据', newFlatData)*/
      // this.flatData = 
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      const id = row ? row.id : ids
      this.open = true
      this.formTitle = '编辑规格'
      this.getCateList()
      return
      article.detail({ id: id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改分类'
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传图片
    uploadImages({ fileList }) {
      // console.log('fileList', fileList)
      this.fileList = fileList
    },
    add(type, index1, index2) {
      console.log('哈哈', type, index1, index2)
      if (type === 1) {
        this.form.specs.push({
          "label": "",
          "spec_value": [
            {
              "label": "",
              "thumb": ""
            }
          ]
        })
      } else if (type === 2) {
        if (!this.form.specs[index1].spec_value[index2].label) return this.$message.error("请输入规格值")
        this.form.specs[index1].spec_value.push({
          "label": "",
          "thumb": ""
        })
      }
    },
    del(type, index1, index2) {
      if (type === 2) {
        this.form.specs[index1].spec_value.splice(index2, 1)
      }
    },
    delSpecs(index) {
      this.form.specs.splice(index, 1)
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.postId !== undefined) {
            console.log('form', this.form)
            return
            updatePost(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            console.log('form', this.form)
            return
            addPost(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less">
.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.box {
  position: relative;
  background-color: #f7f7f7;
  padding: 0 15px 15px 15px;
  margin-bottom: 15px;
  border-radius: 5px;

  .delSpec {
    position: absolute;
    right: -5px;
    top: -5px;
    color: #f5222d;
    cursor: pointer;
  }
}
</style>