import request from '@/utils/request'
const userApi = {
  add: '/goodscate/create',
  del: '/goodscate/delete',
  update: '/goodscate/update',
  editStatus: '/goodscate/update-status',
  detail: '/goodscate/get',
  detailByMId: '/goodscate/get-channel', 
  list: '/goodscate/page',
}
// 查询分类列表
export function list (query) {
  return request({
    url: userApi.list,
    method: 'get',
    params: query
  })
}

// 新增分类
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}
// 删
export function del (parameter) {
  return request({
    url: userApi.del,
    method: 'get',
    params: parameter
  })
}
// 修改分类
export function update (data) {
  return request({
    url: userApi.update,
    method: 'put',
    data: data
  })
}
